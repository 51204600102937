import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography
} from "@mui/material";
import React, { ReactNode } from "react";
import Button from "../Button";
import IconButton from "../IconButton";
import { IconClose } from "../Icons/Icons";

type CustomModalProps = DialogProps & {
  title?: string;
  description?: string;
  open: boolean;
  closeIcon?: boolean;
  onClose?: (event: unknown, reason: "backdropClick" | "escapeKeyDown") => void;
  action?: string;
  onClickAction?: () => void;
  disableOnClickAction?: boolean;
  children?: ReactNode | null;
  fullWidth?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false | string;
  height?: "xs" | "sm" | "md" | "lg" | "xl" | false | string;
  withFooterButtons?: boolean;
  boxClassName?: string;
};

function Modal({
  title = "",
  description = "",
  children = null,
  open,
  closeIcon = true,
  onClose = () => {
    /* parent component should handle this */
  },
  action = "Save",
  onClickAction = () => {
    /* parent component should handle this */
  },
  disableOnClickAction = false,
  fullWidth = false,
  maxWidth = "md",
  withFooterButtons = false,
  boxClassName = "min-w-[600px] border-t border-b border-gray-3",
  ...dialogProps
}: CustomModalProps) {
  const handleClose = (
    event: unknown,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      onClose(event, reason);
    }
  };

  return (
    <Box>
      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        {...dialogProps}
      >
        {title && (
          <DialogTitle className="!p-5">
            <Box>
              <Typography className="font-sans text-base font-semibold">
                {title}
              </Typography>
              {closeIcon ? (
                <IconButton
                  id="close"
                  variant="secondary"
                  size="sm"
                  // @ts-expect-error on click
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: 20,
                    top: 19
                  }}
                  icon={<IconClose width={20} />}
                />
              ) : null}
            </Box>
            {description && (
              <Typography className="text-gray-7 font-sans text-sm font-medium">
                {description}
              </Typography>
            )}
          </DialogTitle>
        )}
        {children && (
          <DialogContent className={boxClassName}>{children}</DialogContent>
        )}
        {withFooterButtons && (
          <DialogActions className="!p-5">
            <Button
              id="cancel"
              variant="secondary"
              message="Cancel"
              // @ts-expect-error on click
              onClick={onClose}
              size="md"
            />
            <Button
              id="submit"
              variant="primary"
              message={action}
              onClick={onClickAction}
              disabled={disableOnClickAction}
              size="md"
            />
          </DialogActions>
        )}
      </Dialog>
    </Box>
  );
}

export default Modal;
